.four_details{

    background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 7)), url("/src/images/elementm_house.jpg");

    height: 100vh;
    color: white;
    object-fit: cover;
    background-repeat: no-repeat;

    background-position: center;
}

