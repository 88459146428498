@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto&display=swap');

body{
    margin: 0;
}
.navbar{
    display: flex;
    justify-content: space-between;
    /*position: fixed;*/
    width: 100%;
    height: 85px;
    top: 0;
}
.navbar-box{
    display: flex;

    justify-content: space-between;
    position: fixed;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
}

.contact{
    display: flex;
    align-items: center;
}
.contact p{
    color: white;
    padding-right: 10px;
    font-family: 'Roboto', sans-serif;

}
.messanger_icon{
    display: flex;
    justify-content: center;
    align-items: center;

}
.messanger_icon a{
    padding-left: 10px;
    color: white;
}
.logo{
    font-size: 36px;
    height: 85px;
}
.logo img{
    width: 200px;
    /*height: 100px;*/
}
