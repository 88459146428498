@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto&display=swap');

.home{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
    /*grid-template-rows: 500px;*/
    align-items: end;
    margin-top: 2%;
    /*background: url("/src/images/elementm9.png");*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    height: 430px;


}
.home a{

    /*width: 300px;*/
    /*height: 450px;*/


}

.one{

    /*background: url("/src/images/elementm4.png");*/
    border: grey 1px solid;
    /*height: 300px;*/
    /*color: red;*/
    /*width: 200px;*/
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: right;
    z-index: 2;
    opacity: 1;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
}
.one_mask{
    background: url("/src/images/elementm_auto.jpg");

    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    position: absolute;

    z-index: 2;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;

}
.one_mask:hover{
    opacity: 1;
    text-decoration: none;
}
.one_bg{

    /*background: url("/src/images/elementm4.png");*/
    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    /*cursor: pointer;*/
    object-fit: cover;
    /*border-radius: 10px;*/
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: bottom;

    opacity: 0.7;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
    position: inherit;
}
.one_bg p{
    font-size: 18px;
    text-transform: lowercase;
    display: grid;
    justify-content: center;
}
.one_bg:hover{
    /*opacity: 0;*/

}


/*.two{*/
/*    background: url("/src/images/elementm_computer.jpg");*/
    /*background: url("/src/images/elementm5.png");*/
    /*border: red 1px solid;*/
    /*height: 350px;*/
    /*color: red;*/
    /*width: 500px;*/
    /*cursor: pointer;*/
    /*object-fit: cover;*/
    /*border-radius: 10px;*/
    /*background-repeat: no-repeat;*/
    /*background-position: bottom;*/
    /*background-size: 300px;*/
    /*margin-bottom: 4%;*/
/**/
/**/
/**/
/**/
/*}*/
.two{

    /*background: url("/src/images/elementm4.png");*/
    border: grey 1px solid;
    /*height: 300px;*/
    /*color: red;*/
    /*width: 200px;*/
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: right;
    z-index: 2;
    opacity: 1;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
}
.two_mask{
    background: url("/src/images/elementm_computer.jpg");

    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    position: absolute;

    z-index: 2;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;

}
.two_mask:hover{
    opacity: 1;
    text-decoration: none;
}
.two_bg{

    /*background: url("/src/images/elementm5.png");*/
    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    /*cursor: pointer;*/
    object-fit: cover;
    /*border-radius: 10px;*/
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: bottom;

    opacity: 0.7;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
    position: inherit;
}
.two_bg p{
    font-size: 18px;
    text-transform: lowercase;
    display: grid;
    justify-content: center;
}
/*.three{*/
/*    background: url("/src/images/elementm_dental3.jpg");*/
/*    color: red;*/
/*    height: 300px;*/
/*    cursor: pointer;*/
/*    object-fit: cover;*/
/*    border-radius: 10px;*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/**/
/**/
/**/
/**/
/*}*/
.three{

    /*background: url("/src/images/elementm4.png");*/
    border: grey 1px solid;
    /*height: 300px;*/
    /*color: red;*/
    /*width: 200px;*/
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: right;
    z-index: 2;
    opacity: 1;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
}
.three_mask{
    background: url("/src/images/elementm_dental3.jpg");

    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    position: absolute;

    z-index: 2;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;

}
.three_mask:hover{
    opacity: 1;
    text-decoration: none;
}
.three_bg{

    /*background: url("/src/images/elementm6.png");*/
    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    /*cursor: pointer;*/
    object-fit: cover;
    /*border-radius: 10px;*/
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: bottom;

    opacity: 0.7;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
    position: inherit;
}
.three_bg p{
    font-size: 18px;
    text-transform: lowercase;
    display: grid;
    justify-content: center;
}
/*.four{*/
/**/
/*    height: 380px;*/
/*    cursor: pointer;*/
/*    background: url("/src/images/elementm_house.jpg");*/
    /*border: red 1px solid;*/

    /*color: red;*/
    /*width: 500px;*/

    /*object-fit: cover;*/
    /*border-radius: 10px;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
/**/
/**/
/**/
/*}*/
.four{

    /*background: url("/src/images/elementm4.png");*/
    border: grey 1px solid;
    /*height: 300px;*/
    /*color: red;*/
    /*width: 200px;*/
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: right;
    z-index: 2;
    opacity: 1;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
}
.four_mask{
    background: url("/src/images/elementm_house.jpg");

    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    position: absolute;

    z-index: 2;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;

}
.four_mask:hover{
    opacity: 1;
    text-decoration: none;
}
.four_bg{

    /*background: url("/src/images/elementm7.5.png");*/
    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    /*cursor: pointer;*/
    object-fit: cover;
    /*border-radius: 10px;*/
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: bottom;

    opacity: 0.7;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
    position: inherit;
}
.four_bg p{
    font-size: 18px;
    text-transform: lowercase;
    display: grid;
    justify-content: center;
}
/*.five{*/
/**/
/*    background: url("/src/images/elementm_coffee.jpg");*/
    /*border: red 1px solid;*/

    /*color: red;*/
    /*width: 500px;*/

    /*object-fit: cover;*/
    /*border-radius: 10px;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    /**/
    /**/
    /**/
    /*height: 450px;*/
    /*cursor: pointer;*/
/*}*/
.five{

    /*background: url("/src/images/elementm4.png");*/
    border: grey 1px solid;
    /*height: 300px;*/
    /*color: red;*/
    /*width: 200px;*/
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: right;
    z-index: 2;
    opacity: 1;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
}
.five_mask{
    background: url("/src/images/elementm_coffee.jpg");

    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    position: absolute;

    z-index: 2;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;

}
.five_mask:hover{
    opacity: 1;
    text-decoration: none;
}
.five_bg{

    /*background: url("/src/images/elementm8.png");*/
    /*border: red 1px solid;*/
    height: 430px;
    color: red;
    width: 300px;
    /*cursor: pointer;*/
    object-fit: cover;
    /*border-radius: 10px;*/
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: bottom;

    opacity: 0.7;
    display: grid;
    text-decoration: none;


    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-transform: uppercase;
    position: inherit;
}
.five_bg p{
    font-size: 18px;
    text-transform: lowercase;
    display: grid;
    justify-content: center;
}
.main_text{
    margin-top: 4%;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 36px;
    color: white;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;

}
