.five_details{

    background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 7)), url("/src/images/elementm_coffee.jpg");
    background-size: cover;
    height: 100vh;

    color: white;
    object-fit: cover;
    border-radius: 10px;
    background-repeat: no-repeat;

    background-position: center;
}
.five_details p{
    margin: 0 auto;
    padding: 5% 0;
    width: 800px;
}
