@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto&display=swap');


.one_details {
    /*background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 7)), url("/src/images/elementm_auto.jpg");*/
    background: url("/src/images/elementm_auto.jpg");
    height: 680px;
    width: 100%;
    color: white;
    /*object-fit: cover;*/
    /*border-radius: 10px;*/
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    /*background-position: center;*/

}
.one_details_box{
    display: flex;
    justify-content: space-around;
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
    height: 630px;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;

}
.one_details_box h2{
    font-size: 60px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;


}
.one_details_box h3{
    display: flex;

    justify-content: center;
    /*padding: 0 20px;*/
    font-family: 'Roboto', sans-serif;
}
.one_details_box p{
    display: flex;
    /*width: 200px;*/
    /*justify-content: center;*/
    /*padding: 0 20px;*/
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}
.one_details_h{
    /*align-items: center;*/
}
.one_details_icons{

    /*height: 50px;*/
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));

}
.messanger{
    display: flex;
    justify-content: center;
    /*align-items: end;*/

    bottom: 0;
    height: 50px;
}
.messanger a{
    padding-left: 10px;
    color: white;
}
.one_details_text{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}
.details_text_box{
    width: 70%;
}
.details_text_box h2, h3{
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}
.one_details_text h2{
    display: flex;
    justify-content: center;
}
.text_icons{
    display: flex;
    justify-content: center;
}
.text_icons_box{
    margin: 0 5%;

}
.text_icons_box h3{
    display: flex;
    justify-content: center;
}
.text_icons_one{
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50%;
}

.text_icons_one svg{
    font-size: 60px;

}
.text_icons_two{
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50%;
}
.text_icons_two svg{
    font-size: 60px;

}
.text_icons_three{
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50%;
}
.text_icons_three svg{
    font-size: 60px;

}
.one_details_bg1{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_auto_details1.jpg");
    width: 300px;
    height: 500px;
    border-radius: 10px;
    background-position: center;
    /*display: grid;*/
    display: flex;
    align-items: center;
    justify-content: center;
}
.one_details_bg2{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_auto_details2.jpg");
    width: 300px;
    height: 500px;
    border-radius: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.one_details_bg3{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_auto_details3.jpg");
    width: 300px;
    height: 500px; border-radius: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
