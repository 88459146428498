.three_details{
    /*background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 7)), url("/src/images/elementm_dental3.jpg");*/

    /*height: 100vh;*/
    color: white;
    /*object-fit: cover;*/
    /*border-radius: 10px;*/
    /*background-repeat: no-repeat;*/
    /**/
    /*background-position: center;*/

}

.three_details h2{
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif
}
.three_details_box{
    display: flex;
    justify-content: space-around;
}
.three_details_box h3{
    display: flex;

    justify-content: center;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
}
.three_details_bg1{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_dental_details1.jpg");
    width: 300px;
    height: 500px;
    border-radius: 10px;
    background-position: center;
    /*display: grid;*/
    display: flex;
    align-items: center;
    justify-content: center;
}
.three_details_bg2{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_dental_details2.jpg");
    width: 300px;
    height: 500px;
    border-radius: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.three_details_bg3{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_dental_details3.jpg");
    width: 300px;
    height: 500px; border-radius: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

}

