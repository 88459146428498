.two_details{
    /*background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 7)), url("/src/images/elementm_computer.jpg");*/
    background: url("/src/images/elementm_computer2.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 630px;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /*height: 100vh;*/
    color: white;

    /*object-fit: cover;*/
    /*border-radius: 10px;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/

}
.two_details h2{
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif
}
.two_details_box{
    display: flex;
    justify-content: space-around;
    padding: 4% 0;
    background-color: #EDECEC;
}
.two_details_box h3{
    display: flex;

    justify-content: center;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
}
.two_details_title{
    display: flex;
    /*justify-content: space-around;*/
    /*background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, .5), rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));*/
    background: linear-gradient(90deg, rgba(255,255,255, .9) 30%, rgba(255,255,255, .3) 60%);
    /*background-image: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100% );*/
    height: 630px;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;

}
.two_details_h{
    width: 30%;
    margin-left: 10%;
}
.two_details_h h2{
    color: white;
    width: 150px;
    font-size: 20px;
    background:#D72D2D ;
    /*text-transform: uppercase;*/
    font-family: 'Roboto', sans-serif;
}
.two_details_h h3{
    color: black;
    font-size: 40px;
    /*text-transform: uppercase;*/
    font-family: 'Roboto', sans-serif;
}
.messanger_icon_two{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: end;*/

    bottom: 0;
    height: 50px;
}
.messanger_icon_two a{
    padding-left: 10px;
    color: #751FFF;
}

.two_details_bg1{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_computer_details1.jpg");
    width: 670px;
    height: 400px;
    /*border-radius: 10px;*/
    background-position: center;
    /*display: grid;*/
    display: flex;
    align-items: center;
    justify-content: center;
}
.two_details_bg2{
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/src/images/elementm_computer_details2.jpg");
    width: 670px;
    height: 400px;
    /*border-radius: 10px;*/
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
.two_details_discount{

    width: 100%;
    height: 400px;
    /*margin: 0;*/


    /*border-radius: 10px;*/

    background: linear-gradient(90deg, rgba(255,255,255, .3) 30%, rgba(255,255,255, .9) 60%), url("/src/images/elementm_computer_details4.jpg");
    /*background-position: center;*/
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    /*clip-path: polygon(0 0, 80% 0, 90% 100%, 0% 100%);*/
    color: black;


}
.two_details_discount_box{
    width: 80%;
    display: flex;
    justify-content: end;
}
.two_details_services{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 200px;
    width: 800px;
    margin: 0 auto;
}
.two_details_services svg{
    font-size: 50px;
    margin: 10px;
    border: 1px solid white;
    border-radius: 50%;
    padding: 10px;
    color: #751FFF;
}
.two_details_services p{
    font-size: 18px;
    font-family: Roboto, sans-serif;

}
.services_box{
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

